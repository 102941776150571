export class AnalyticsEvents {
  static readonly Login = {
    RenderedLoginPage: "login rendered page",
  };

  static readonly CreateModel = {
    AddedTrainingImage: "create model added training image",

    DeletedTrainingImage: "create model deleted training image",
    DeletedAllTrainingImages: "create model deleted all training images",
    UpdatedTrainingImageDescription:
      "create model updated training image description",

    TrainingQueued: "create model started training",

    TrainingImageBackgroundRemoved:
      "create model removed training image background",
    AllTrainingImagesBackgroundRemoved:
      "create model removed all training images background",
  };

  static readonly Navigation = {
    ClickedModels: "navigation clicked models",
    ClickedImages: "navigation clicked images",
    ClickedCanvas: "navigation clicked canvas",
    ClickedLogout: "navigation clicked logout",
    ClickedUpgrade: "navigation clicked upgrade",
    ClickedSpeedUpProcessing: "navigation clicked speed up processing",
    ClickedSubscriptionEndingSoon:
      "navigation clicked subscription ending soon",

    ClickedHelpCenter: "navigation clicked help center",
    ClickedChat: "navigation clicked chat",
    ClickedFeedback: "navigation clicked feedback",
    ClickedSupport: "navigation clicked support",
    ClickedTeamSettings: "navigation clicked team settings",
    ClickedProfile: "navigation clicked profile",

    ClickedUnity: "navigation clicked unity",

    ClickedPixelate: "navigation clicked pixelate",
    ClickedUpscale: "navigation clicked upscale",
    ClickedRemoveBackground: "navigation clicked remove background",
    ClickedRestyle: "navigation clicked restyle",
    ClickedVectorize: "navigation clicked vectorize",

    ClickedSkyboxes: "navigation clicked skyboxes",
    ClickedUpscaleSkybox: "navigation clicked upscale skybox",

    ClickedTextures: "navigation clicked textures",
    ClickedUpscaleTexture: "navigation clicked upscale texture",

    ClickedReframe: "navigation clicked reframe",
  };

  static readonly Model = {
    Copied: "model copied",
    Deleted: "model deleted",
    Exported: "model exported",
    CopiedTrained: "model copied trained",
    UploadedThumbnail: "model uploaded thumbnail",
    Uploaded: "model uploaded",
    UsedDeprecatedGeneratorId: "model used deprecated generator id",
    UsedDeprecatedGeneratorRoute: "model used deprecated generator route",
  };

  static readonly Inference = {
    SelectedModel: "generate inference selected model",
    CreatedInference: "generate inference created inference",
    ImportedReferenceImage: "generate inference imported reference image",
    ImportedIpAdapterImage: "generate inference imported ip adapter image",
    UsedDeprecatedImportAssetId:
      "generate inference used deprecated import asset id",
    ImportedSecondReferenceImage:
      "generate inference imported second reference image",
  };

  static readonly ImageLibrary = {
    RemovedBackground: "asset removed background",
    UpscaledImage: "asset upscaled",
    DownloadedImage: "asset downloaded",
    PixelatedImage: "asset pixelated",
    DeletedImage: "asset deleted",
    UploadedImage: "asset uploaded",
    VectorizedImage: "asset vectorized",
  };

  static readonly Signup = {
    ReferralCaptured: "signup referral captured",
  };

  static readonly User = {
    PolicyAccepted: "user policy accepted",
    Onboarded: "user onboarded",
    accountDeleted: "user account deleted",
  };

  static readonly Canvas = {
    DrawMask: "canvas draw mask",
    Sketch: "canvas sketch",
    Downloaded: "canvas downloaded",
    NewCanvasCreated: "canvas created",
    UndoTriggered: "canvas undo",
    RedoTriggered: "canvas redo",
    CreatedInference: "canvas created inference",
    Delete: "canvas deleted",
    DisplayLockModal: "canvas display lock modal",
    Draw: "canvas draw",
    Duplicate: "canvas duplicated",
    ForceUnlock: "canvas forced unlock",
    ImportedFromGallery: "canvas imported from gallery",
    OpenSection: "canvas opened section",
    RemoveBackground: "canvas removed background",
    Upload: "canvas uploaded",
    Upscale: "canvas upscaled",

    LiveToggle: "canvas toggled live",
    SeedClick: "canvas clicked seed",
    OpenedSetting: "canvas opened setting",
    SendOutputToInput: "canvas sent output to input",
  };

  static readonly Gallery = {
    ChangeGridView: "gallery changed view",
    ChangeColumnsNumber: "gallery changed columns number",
  };

  static readonly Team = {
    CreatedTeam: "team created",
    DeletedTeam: "team deleted",
    AddedMember: "team added member",
    RemovedMember: "team removed member",
    CreatedApiKey: "team created api key",
    DeletedApiKey: "team deleted api key",
    LeftTeam: "team member left",
    RemovedPendingMember: "team removed pending member",
  };

  static readonly Subscription = {
    RedirectedToPaymentPage: "subscription redirected to payment page",
    CancelledPlan: "subscription cancelled plan",
    UpgradedPlan: "subscription upgraded plan",
    DowngradedPlan: "subscription downgraded plan",
    ReactivatedPlan: "subscription reactivated plan",
    AddedSeats: "subscription added seats",
    RemovedSeats: "subscription removed seats",
  };

  static readonly Oscu = {
    RedirectedToPaymentPage: "oscu redirected to payment page",
  };

  static readonly Search = {
    Searched: "search searched",
    ImportedImage: "search imported image",
  };

  static readonly AssetZoom = {
    UsedDeprecatedOpenAssetId: "asset zoom used deprecated open asset id",
    CreatePreset: "asset zoom created preset",
    OpenInUnity: "asset zoom opened in unity",
    Downloaded: "asset zoom downloaded",
  };

  static readonly Pixelate = {
    ImportedReferenceImage: "pixelate imported reference image",
  };

  static readonly Upscale = {
    ImportedReferenceImage: "upscale imported reference image",
  };

  static readonly RemoveBackground = {
    ImportedReferenceImage: "remove background imported reference image",
  };

  static readonly Vectorize = {
    ImportedReferenceImage: "vectorize imported reference image",
  };

  static readonly Skybox = {
    ImportedReferenceImage: "skybox/importedReferenceImage",
    GeneratedSkyboxes: "skybox created inference",
    UpscaledSkybox: "skybox upscaled",
  };

  static readonly SkyboxUpscale = {
    ImportedStyleImage: "skybox upscale imported style image",
  };

  static readonly Restyle = {
    CreatedInference: "restyle created inference",
    ImportedReferenceImage: "restyle imported reference image",
    ImportedStyleImage: "restyle imported style image",
  };

  static readonly Home = {
    ClickedTool: "home clicked tool",
    ClickedViewAllTools: "home clicked view all tools",

    ClickedUseCase: "home clicked use case",

    ClickedTutorial: "home clicked tutorial",
  };

  static readonly Tools = {
    ClickedTool: "tools clicked tool",
  };

  static readonly Blend = {
    Tested: "compose tested",
    Created: "compose created",
  };

  static readonly TourGuide = {
    OpenedTutorial: "tour guide opened tutorial",
    ClosedTutorial: "tour guide closed tutorial",
    OpenedPage: "tour guide opened page",
    ClosedPage: "tour guide closed page",
    ClickedLearnMore: "tour guide clicked learn more",
  };

  static readonly Reframe = {
    GeneratedReframe: "reframe created inference",
    ImportedReferenceImage: "reframe imported reference image",
  };

  static readonly DataDeletion = {
    ClickedTopBarDataDeletion: "data deletion clicked top bar data deletion",
    ClickedAssetDataDeletion: "data deletion clicked asset data deletion",
    ClickedCanvasDataDeletion: "data deletion clicked canvas data deletion",
  };

  static readonly CardAction = {
    ReloadedParameters: "card action reloaded parameters",
    ReusedModel: "card action reused model",
    ReusedImage: "card action reused image",
    ReusedPrompt: "card action reused prompt",
    ReusedSeed: "card action reused seed",
    ClickedUpscale: "card action clicked upscale",
    ClickedRetouch: "card action clicked retouch",
    ClickedLive: "card action clicked live",
    ClickedMore: "card action clicked more",
    ClickedSearchSimilar: "card action clicked search similar",
    ClickedExpand: "card action clicked expand",
  };
}
